<template>
  <v-card
    :class="[$vuetify.breakpoint.smAndUp ? 'pt-5 px-0' : 'pt-4 px-2']"
    width="100%"
    rounded="lg"
    flat
    @drop.prevent="onFileDrop($event)"
    @dragover.prevent="dragover = true"
    @dragenter.prevent="dragover = true"
    @dragleave.prevent="dragover = false"
  >
    <v-row>
      <template v-for="(item, fileIndex) in formData.file">
        <v-col cols="12" sm="6" md="4" lg="3" :key="fileIndex">
          <v-card outlined height="100%" rounded="lg">
            <v-card-actions>
              <v-spacer />
              <v-btn icon x-small @click="copyItem(fileIndex, item)">
                <feather size="18" type="copy" stroke="black" />
              </v-btn>
              <v-btn
                icon
                x-small
                class="ml-3"
                @click="deleteItem(fileIndex, item)"
              >
                <feather size="18" type="trash" stroke="red" />
              </v-btn>
            </v-card-actions>
            <v-card-text class="pt-1 px-2">
              <file-viewer :file="item" />
              <v-row>
                <v-col cols="12">
                  <span
                    class="subtitle-2 font-weight-regular onSurface--text"
                    v-text="item.name"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols="4" class="pt-0">
                  <span
                    class="subtitle-2 font-weight-regular"
                    v-text="'방식'"
                  />
                </v-col>
                <v-col cols="8" class="d-flex pt-0">
                  <v-menu rounded="lg" bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        class="pl-2 pr-0"
                        height="20px"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                      >
                        <span
                          class="subtitle-2 font-weight-regular onSurface--text overflow-x-hidden"
                          v-text="getOption(item).method.split('(')[0]"
                        />
                        <v-icon size="20" class="ml-2">
                          mdi-chevron-down
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item
                        v-for="(printOption, index) in printOption"
                        :key="index"
                        @click="methodChange(fileIndex, index)"
                      >
                        <v-list-item-title>
                          <span
                            class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                            v-text="printOption.method"
                          />
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="pt-0">
                  <span
                    class="subtitle-2 font-weight-regular"
                    v-text="'소재'"
                  />
                </v-col>
                <v-col cols="8" class="d-flex pt-0">
                  <span class="subtitle-2 font-weight-regular onSurface--text">
                    <v-menu rounded="lg" bottom offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          class="pl-2 pr-0"
                          height="20px"
                          min-width="10px"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                        >
                          <span
                            class="subtitle-2 font-weight-regular onSurface--text"
                            v-text="
                              printOption[item.method].material[
                                item.material
                              ].name.split('(')[0]
                            "
                          />
                          <v-icon size="20" class="ml-2">
                            mdi-chevron-down
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="(materialList, index) in printOption[
                            item.method
                          ].material"
                          :key="index"
                          @click="materialChange(fileIndex, index)"
                        >
                          <v-list-item-title>
                            <span
                              class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                              v-text="materialList.name"
                            />
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </span>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="pt-0">
                  <span
                    class="subtitle-2 font-weight-regular"
                    v-text="'색상'"
                  />
                </v-col>
                <v-col cols="8" class="d-flex pt-0">
                  <span class="subtitle-2 font-weight-regular onSurface--text">
                    <v-menu rounded="lg" bottom offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          class="pl-2 pr-0"
                          height="20px"
                          min-width="10px"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                        >
                          <span
                            class="subtitle-2 font-weight-regular onSurface--text"
                            v-text="getOption(item).color.name.split('(')[0]"
                          />
                          <v-icon size="20" class="ml-2">
                            mdi-chevron-down
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list dense>
                        <v-list-item
                          v-for="(colorList, index) in printOption[item.method]
                            .material[item.material].color"
                          :key="index"
                          @click="item.color = index"
                        >
                          <v-list-item-title>
                            <v-icon
                              size="14"
                              :color="colorList.color"
                              class="mr-3"
                              style="border: 1px solid grey; border-radius: 50%"
                            >
                              mdi-circle
                            </v-icon>
                            <span
                              class="ml-2 subtitle-2 font-weight-regular onSurface--text"
                              v-text="colorList.name"
                            />
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </span>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="4" class="pt-0">
                  <span
                    class="subtitle-2 font-weight-regular"
                    v-text="'수량'"
                  />
                </v-col>
                <v-col cols="8" class="d-flex pt-0">
                  <span class="subtitle-2 font-weight-regular onSurface--text">
                    <v-menu
                      rounded="lg"
                      bottom
                      offset-y
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          text
                          class="px-2"
                          height="20px"
                          min-width="10px"
                          v-bind="attrs"
                          v-on="on"
                          outlined
                        >
                          <span
                            class="subtitle-2 font-weight-regular onSurface--text"
                            v-text="item.quantity.toLocaleString() + 'EA'"
                          />
                        </v-btn>
                      </template>
                      <v-card width="160px">
                        <v-card-text>
                          <v-text-field
                            :label="'작업 수량'"
                            v-model="item.quantity"
                            outlined
                            type="number"
                            hide-details
                            dense
                          />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
      <v-col
        cols="12"
        :sm="formData.file && !formData.file[0] ? '12' : '6'"
        :md="formData.file && !formData.file[0] ? '12' : '4'"
        :lg="formData.file && !formData.file[0] ? '12' : '3'"
      >
        <v-card
          outlined
          @click="$refs.uploader.click()"
          height="100%"
          min-height="220px"
          :style="{ backgroundColor: dragover ? 'rgba(0, 0, 0, 0.05)' : '' }"
          rounded="lg"
          style="border: 3px dashed #0090ff"
        >
          <input
            ref="uploader"
            class="d-none"
            type="file"
            @change="onFileInput"
            multiple
          />
          <v-card-text class="d-flex text-center" style="height: 100%">
            <div class="my-auto mx-auto">
              <v-icon size="40" class="d-flex" color="primary">
                mdi-cloud-upload
              </v-icon>
              <div>
                <span
                  class="subtitle-2 font-weight-bold primary--text"
                  v-text="'추가 파일 업로드'"
                />
              </div>
              <div>
                <span
                  class="mx-auto caption text-center"
                  v-html="
                    '견적 접수 전 파일은' + '<br/>' + '서버에 저장되지 않습니다'
                  "
                />
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<style scoped>
.row {
  margin: 0px;
  padding: 0px;
}
.col {
  padding: 0px;
}
::v-deep .v-input__slot {
  min-height: 36px !important;
}
</style>
<script>
import _ from "lodash";
import constants from "@/utils/constants";
import FileViewer from "@/components/Preview/fileViewer.vue";
export default {
  components: { FileViewer },
  props: { formData: Object },
  data: () => ({
    fileInterface: {
      method: 0,
      material: 0,
      color: 0,
      quantity: 1,
      memo: "",
      previewImg: "",
      dimension: "",
      status: 0,
      uploader: "customer",
      print: false,
      price: 0,
    },
    // 파일 별 옵션을 위한 변수
    printOption: constants.printOption,
    materialList: constants.printOption[0].material,
    colorList: constants.printOption[0].material[0].color,
    // 파일 추가를 위한 변수
    dragover: false,
  }),
  methods: {
    copyItem(fileIndex, item) {
      let newFile = new File([item], item.name, { type: item.type });
      newFile = _.merge(newFile, item);
      this.formData.file.splice(fileIndex + 1, 0, newFile);
    },
    deleteItem(fileIndex) {
      this.formData.file.splice(fileIndex, 1);
    },
    getOption(i) {
      return {
        method: this.printOption[i.method].method,
        material: this.printOption[i.method].material[i.material].name,
        color: this.printOption[i.method].material[i.material].color[i.color],
      };
    },
    methodChange(a, v) {
      this.formData.file[a].method = v;
      this.formData.file[a].material = 0;
      this.formData.file[a].color = 0;
      this.materialList = this.printOption[v].material;
      this.colorList = this.printOption[v].material[0].color;
      this.$forceUpdate();
    },
    materialChange(a, v) {
      this.formData.file[a].material = v;
      this.formData.file[a].color = 0;
      this.colorList =
        this.printOption[this.formData.file[a].method].material[v].color;
      this.$forceUpdate();
    },
    // 드래그 & 드랍 파일 인식
    onFileDrop(e) {
      this.dragover = false;
      e.dataTransfer.files.forEach((v) => this.addFile(v));
    },
    // 일반 인풋 파일 인식
    onFileInput(event) {
      event.target.files.forEach((v) => this.addFile(v));
    },
    addFile(v) {
      const fileType = v.name.slice(v.name.lastIndexOf(".") + 1).toLowerCase();
      if (["3dm", "skp", "f3d", "igs", "gcode", "sldprt", "ply", "hfb"].includes(fileType)) {
        this.$toasted.global.error(
          fileType + " 파일은 확인이 불가합니다. STL 파일로 올려주세요!"
        );
        return;
      }
      if (!["stl", "obj", "3mf"].includes(fileType)) {
        this.$toasted.global.error(
          "STL, OBJ, 3MF 파일 외에는 답변이 어려울 수 있습니다"
        );
      }

      v = _.merge(v, this.fileInterface);
      if (v.size >= 50000000) {
        this.$toasted.global.error(
          `50MB 이하 파일만 등록 가능합니다. <br/> 우측 하단 채널톡에서 용량 안내를 받아보세요!`
        );
        return;
      }
      this.formData.file.push(v);
    },
  },
};
</script>

import { render, staticRenderFns } from "./OrderFormSection.vue?vue&type=template&id=ac2b5028&scoped=true"
import script from "./OrderFormSection.vue?vue&type=script&lang=js"
export * from "./OrderFormSection.vue?vue&type=script&lang=js"
import style0 from "./OrderFormSection.vue?vue&type=style&index=0&id=ac2b5028&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac2b5028",
  null
  
)

export default component.exports